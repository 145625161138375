import {useBearerTokenHeaders} from "../utils/functions";
import axios from "../axios/axios-kaqadmin";

export const payoutService = {
    fetchArrears,
    setInitialArrears,
    createPaymentPlan,
    getPaymentPlanInWeeks,
    generatePayout,
    getWeekPayout,
    getPayoutHistory,
    getPayoutSummary,
    downloadData,
    getGeneratePayout,
    getDriverPayoutDetails,
    deleteGeneratePayout,
    confirmGeneratePayout,
    resendGeneratePayoutNotifications,
    arrearsOverview,
    getUserArrearsDetails,
    investigateArrears,
}

function arrearsOverview(investigate){
    return new Promise((resolve) => {
        const url = '/payouts/get-arrears-overview' + (investigate ? '?investigate=true' : '');
        axios.get(url, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}

function investigateArrears(payload){
    return new Promise((resolve, reject) => {
        axios.post('/payouts/investigate-arrears', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function downloadData(payload){
    return new Promise((resolve, reject) => {
        axios.post('/download-data', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function getUserArrearsDetails(userId,week){
    return new Promise((resolve, reject) => {
        axios.get(`/payouts/get-arrears-details/${userId}?week=${week}`, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function getWeekPayout(payload){
    return new Promise((resolve, reject) => {
        axios.post('/payouts/get-week-payout', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function fetchArrears(userId){
    return new Promise((resolve, reject) => {
        axios.get('/payouts/get-arrears/' + userId, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}

function setInitialArrears(payload){
    return new Promise((resolve, reject) => {
        axios.post('/payouts/set-initial-arrears', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function createPaymentPlan(payload){
    return new Promise((resolve, reject) => {
        axios.post('/payouts/create-payment-plan-in-weeks', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function getPaymentPlanInWeeks(payload){

    return new Promise((resolve, reject) => {
        axios.post('/payouts/get-payment-plan-in-weeks',payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}


function generatePayout(payload){
    return new Promise((resolve, reject) => {
        axios.post('/payouts/generate', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function getGeneratePayout(payload){
    return new Promise((resolve, reject) => {
        axios.post('/payouts/get-generated-payout', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function getDriverPayoutDetails(payload){
    return new Promise((resolve, reject) => {
        axios.post('/payouts/get-driver-generated-payout', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function getPayoutHistory(payload){
    return new Promise((resolve, reject) => {
        axios.post('/payouts/get-payout-history', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}
function getPayoutSummary(payload){
    return new Promise((resolve) => {
        axios.post('/payouts/get-payout-summary', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function deleteGeneratePayout(payload){
    return new Promise((resolve, reject) => {
        axios.post('/payouts/delete-generated-payout', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function confirmGeneratePayout(payload){
    return new Promise((resolve, reject) => {
        axios.post('/payouts/confirm-generated-payout', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function resendGeneratePayoutNotifications(payoutId){
    return new Promise((resolve, reject) => {
        axios.get('/payouts/resend-generated-payout-notifications/'+payoutId, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}