<template>
  <div class="inbox-leftbar">
    <!--    <b-button block variant="danger" @click="showModal = true">Compose</b-button>-->
    <!-- start search box -->
    <form class="search-bar">
      <div class="position-relative">
        <input
            type="text"
            class="form-control form-control-light"
            placeholder="Search Driver ..."
            @keyup="onSearch"
            v-model="search"
        />
        <span class="mdi mdi-magnify"></span>
      </div>
    </form>
    <!-- end search box -->

    <hr>
    <ul class="list-unstyled">

      <li class="mb-1">
        <b-checkbox
            @change="onDriverTypeChanged"
            v-model="showPremiumPartners"
        > Premium partners
        </b-checkbox>
      </li>
      <li class="mb-1">
        <b-checkbox
            @change="onDriverTypeChanged"
            v-model="showMediumPartners"
        > Medium partners
        </b-checkbox>
      </li>
      <li class="mb-1">
        <b-checkbox
            @change="onDriverTypeChanged"
            v-model="showLightPartners"
        > Light partners
        </b-checkbox>
      </li>
      <li class="mb-1">
        <b-checkbox
            @change="onDriverTypeChanged"
            v-model="showLightPartnerPlus"
        > Light partner plus
        </b-checkbox>
      </li>
    </ul>
    <hr>
    <button class="btn btn-success btn-sm" @click="clearSelection()"><i class="fas fa-eraser"></i> Clear Selection
    </button>


    <div class="mail-list mt-4" style="height: 600px; overflow-y: auto;">

      <template v-if="driversLoader">
        <div v-for="(item,i) in 10" :key="i">
          <div class="row mb-2">
            <div class="col-3">
              <div class="photo shine" style="width: 40px;height: 40px;border-radius: 50px;"></div>
            </div>
            <div class="col-9">
              <div class="lines shine" style="margin-top: 17px;"></div>
              <div class="lines shine"></div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-for="(driver, index) in filteredDrivers">
          <div class="media mb-4" style="cursor:pointer;" :key="driver['id']"
               :class="{'active-driver': driver.selected}" @click="driverSelected(driver)">
            <div class="position-relative">
              <template v-if="driver.selected">
                <b-avatar size="md" variant="light" class="text-dark"
                ><i class="fa fa-check "></i></b-avatar
                >
              </template>
              <template v-else>
                <b-avatar size="md" variant="dark" class="text-light"
                ><span class="">{{ driver.name | initials }}</span></b-avatar
                >
              </template>

            </div>
            <div class="media-body">
              <h5 class="mt-0 mb-0 ml-2 font-14">
                {{ driver.name }} {{ driver['other_names'] }} <span class="badge badge-soft-success">Dossier: {{driver.driver.dossier}}</span>
                <template v-if="driver['countOnlyPendingFiles'] && driver['countOnlyPendingFiles'] > 0">
                  <span class="badge badge-soft-danger float-right ml-2"
                        @click="driverSelected(driver, index)">{{ driver['countOnlyPendingFiles'] }}</span>
                </template>
              </h5>
              <p class="mt-1 mb-0 ml-2 text-muted font-14">
                <span class="w-75">status: {{ driver.status }}
                  <template v-if="driver['countPendingReceiptAdminMessagesFromDriver'] > 0">
                   <span
                       class="float-right text-muted font-weight-normal font-12"
                   ><span
                       class="badge badge-pill p-1 badge-success">{{
                       driver['countPendingReceiptAdminMessagesFromDriver']
                     }}</span> </span
                   >
                </template>
                </span>
              </p>
            </div>
          </div>
        </template>

      </template>


    </div>


  </div>
</template>


<script>

import {Skeleton, SkeletonTheme} from 'vue-loading-skeleton';


/**
 * Side-panel component
 */
export default {

  components: {
    Skeleton, SkeletonTheme
  },
  props: {
    status: {
      default: '',
      type: String
    },
    driverSelect: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      search: '',
      filteredDrivers: [],
      showPremiumPartners: true,
      showMediumPartners: true,
      showLightPartners: true,
      showLightPartnerPlus: true,
      firstCreated: true,
      driversLoader: true,
      selectedDriver: ''
    };
  },
  computed: {
    drivers() {
      // if (this.driverSelect && drivers.length > 0) {
      //   console.log('drivers', drivers)
      //   this.driverSelected(drivers[0]);
      // }
      return this.$store.getters.GET_DRIVERS;
    },
    isSearching() {
      return this.$store.getters.SECTION_LOADER;
    }
  },
  watch: {
    drivers: function (drivers) {
      if (this.filteredDrivers.length < 1 && this.firstCreated === true) {
        this.filteredDrivers = drivers;
        this.firstCreated = false;
        if(this.driverSelect && drivers.length > 0){
          this.driverSelected(drivers[0]);
        }
      }
    }

  },
  methods: {
    onSearch() {
      if (this.search === '') {
        this.filteredDrivers = this.drivers;
        this.showLightPartnerPlus = true
        this.showPremiumPartners = true
        this.showMediumPartners = true
        this.showLightPartners = true
        // this.removeAllDriverSelections();
        return;
      }

      this.showLightPartnerPlus = false
      this.showPremiumPartners = false
      this.showMediumPartners = false
      this.showLightPartners = false

      this.filteredDrivers = this.drivers.filter(
          e => e['name'].toLowerCase().includes(this.search.toLowerCase())
              || (e['other_names'] && e['other_names'].toLowerCase().includes(this.search.toLowerCase())) || e['driver']['dossier'].toLowerCase().includes(this.search.toLowerCase())
      )
      this.driverSelected(this.selectedDriver);

    },
    driverSelected(driver) {
      this.removeAllDriverSelections();
      this.setDriverCheckBox(driver);

      this.$emit('onDriverSelected', driver)
    },

    setDriverCheckBox(driver) {
      if (!driver) {
        return;
      }
      this.selectedDriver = driver;

      let driverIndex = this.filteredDrivers.findIndex(e => e.id === this.selectedDriver.id);
      this.filteredDrivers[driverIndex]['selected'] = true;
    },

    clearSelection() {
      this.removeAllDriverSelections();
      this.search = '';
      this.onSearch();
      this.$emit('removeDriverSelection', '')
    },

    removeAllDriverSelections() {
      this.filteredDrivers = this.filteredDrivers.map(e => ({
        ...e,
        selected: false
      }))
    },
    onDriverTypeChanged() {

      this.filteredDrivers = [];

      if (this.showPremiumPartners) {
        const premiumPartnerTypes = this.drivers.filter(
            e => e['prospective_driver']['service_type'].toLowerCase() === 'premium_partner'
        )
        this.filteredDrivers.push(...premiumPartnerTypes)
      }

      if (this.showLightPartners) {
        const lightPartnerTypes = this.drivers.filter(
            e => e['prospective_driver']['service_type'].toLowerCase() === 'light_partner'
        )
        this.filteredDrivers.push(...lightPartnerTypes)
      }
      //
      if (this.showLightPartnerPlus) {
        const lightPlusPartnerTypes = this.drivers.filter(
            e => e['prospective_driver']['service_type'].toLowerCase() === 'light_partner_plus'
        )
        this.filteredDrivers.push(...lightPlusPartnerTypes)
      }
      //
      if (this.showMediumPartners) {
        const mediumPartnerTypes = this.drivers.filter(
            e => e['prospective_driver']['service_type'].toLowerCase() === 'medium_partner'
        )
        this.filteredDrivers.push(...mediumPartnerTypes)
      }
      // this.driverSelected(this.filteredDrivers[0], 0);
    },

    getDrivers(showLoader = false) {
      this.driversLoader = true;
      this.$store.dispatch('getDrivers', {limit: 1000, status: this.status, showLoader: showLoader}).then((drivers) => {
      }).finally(() => {
        this.driversLoader = false;
      });
    },
  },

  created() {
    this.getDrivers(false)
  },
};
</script>

<style scoped>
.active-driver {
  /*background-color: #f3f7f9;*/
  /*border-color: #f3f7f9;*/
  /*padding-top: 10px;*/
  /*padding-bottom: 10px;*/
  /*!*padding-left: 1px;*!*/
  /*!*padding-right: 1px;*!*/
  /*border-radius: 5px;*/
}
</style>